import type { Components } from '@mui/material/styles/components';
import { BLACK } from '~/theme/colors';

const MuiTypography: Components['MuiTypography'] = {
	defaultProps: {
		style: {
			fontFamily: 'PingFang TC',
			fontStyle: 'normal',
		},
	},
	styleOverrides: {
		root: {
			color: BLACK,
		},
	},
	variants: [
		{
			props: { variant: 'h1' },
			style: {
				fontWeight: 600,
				fontSize: '96px',
				lineHeight: '134px',
			},
		},
		{
			props: { variant: 'h2' },
			style: {
				fontWeight: 600,
				fontSize: '60px',
				lineHeight: '84px',
			},
		},
		{
			props: { variant: 'h3' },
			style: {
				fontWeight: 600,
				fontSize: '48px',
				lineHeight: '67px',
			},
		},
		{
			props: { variant: 'h4' },
			style: {
				fontWeight: 600,
				fontSize: '34px',
				lineHeight: '48px',
			},
		},
		{
			props: { variant: 'h5' },
			style: {
				fontWeight: 600,
				fontSize: '24px',
				lineHeight: '34px',
			},
		},
		{
			props: { variant: 'h6' },
			style: {
				fontWeight: 600,
				fontSize: '20px',
				lineHeight: '28px',
			},
		},
		{
			props: { variant: 'subtitle1' },
			style: {
				fontWeight: 500,
				fontSize: '18px',
				lineHeight: '25.2px',
			},
		},
		{
			props: { variant: 'subtitle2' },
			style: {
				fontWeight: 500,
				fontSize: '16px',
				lineHeight: '22px',
			},
		},
		{
			props: { variant: 'body1' },
			style: {
				fontWeight: 400,
				fontSize: '16px',
				lineHeight: '22.4px',
			},
		},
		{
			props: { variant: 'body2' },
			style: {
				fontWeight: 400,
				fontSize: '14px',
				lineHeight: '19.6px',
			},
		},
		{
			props: { variant: 'buttonL' },
			style: {
				fontWeight: 500,
				fontSize: '16px',
				lineHeight: '22.4px',
			},
		},
		{
			props: { variant: 'buttonM' },
			style: {
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '19.6px',
			},
		},
		{
			props: { variant: 'buttonS' },
			style: {
				fontWeight: 500,
				fontSize: '13px',
				lineHeight: '18.2px',
			},
		},
		{
			props: { variant: 'caption' },
			style: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '16.8px',
			},
		},
		{
			props: { variant: 'label' },
			style: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '14px',
			},
		},
		{
			props: { variant: 'inputText' },
			style: {
				fontWeight: 400,
				fontSize: '16px',
				lineHeight: '22.4px',
			},
		},
		{
			props: { variant: 'helper' },
			style: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '16.8px',
			},
		},
		{
			props: { variant: 'tooltip' },
			style: {
				fontWeight: 500,
				fontSize: '10px',
				lineHeight: '14px',
			},
		},
	],
};

export default MuiTypography;
