import { lazy } from 'react';

const CreateASN = lazy(
	() => import('./CreateASN' /* webpackChunkName: 'CreateASN' */)
);

const BatchASNPage = lazy(
	() => import('./BatchASNPage' /* webpackChunkName: 'BatchASNPage' */)
);

const ASNsPage = lazy(
	() => import('./ASNsPage' /* webpackChunkName: 'ASNsPage' */)
);

const WarehouseASNsPage = lazy(
	() => import('./WarehouseASNsPage' /* webpackChunkName: 'ASNsPage' */)
);

const ASNDetail = lazy(
	() => import('./ASNDetail' /* webpackChunkName: 'ASNDetail' */)
);

export { CreateASN, ASNsPage, WarehouseASNsPage, BatchASNPage, ASNDetail };
