import { useMemo } from 'react';
import { pathToRegexp } from 'path-to-regexp';
import { List, Box, Stack } from '@mui/material';
import type { RouteObject } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSvgIcon } from '~/static/farcentLogo.svg';
import { ReactComponent as FarcentIcon } from '~/static/farcent.svg';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { getTokenData } from '~/libraries/jwt';
import useMenuColor from './useMenuColor';
import useRouteData from '~/useRouteData';
import MenuIcon from './MenuIcon';
import MenuTitle from './MenuTitle';
import IconButton from './IconButton';
import LogoutButton from './LogoutButton';

type TMenuItem = {
	title: string;
	path: string;
	index?: boolean;
	icon?: any;
	pathPatterns?: string[];
};

export type TMenuItems = TMenuItem & {
	children?: TMenuItem[];
};

const LogoIcon = () => {
	const { menuTextColor } = useMenuColor();
	return (
		<IconButton disabled clicked={false} sx={{ color: menuTextColor }}>
			<MenuIcon icon={LogoSvgIcon} />
		</IconButton>
	);
};

const Username = () => {
	const { menuTextColor } = useMenuColor();
	const token = getTokenData();

	const username = useMemo(
		() => token?.user?.name ?? token?.user?.email,
		[token]
	);

	return (
		<Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{username}</span>}>
			<Typography
				noWrap
				variant='caption'
				color={menuTextColor}
				sx={{ width: '50px', fontWeight: 500, textAlign: 'center' }}
			>
				{username}
			</Typography>
		</Tooltip>
	);
};
const SideMenu = () => {
	const styles = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const routes = useRouteData();
	const list = useMemo(() => getSideMenuItems(routes), [routes]);

	return (
		<Box sx={styles.container}>
			<Username />
			<Box sx={styles.logoBox}>
				<LogoIcon />
			</Box>
			<Stack direction='column' sx={styles.listContainer}>
				<List sx={styles.list}>
					{list.map((item: TMenuItems) => {
						return (
							<MenuTitle
								key={`${item.title}`}
								title={item.title}
								icon={item.icon}
								hasChildren={false}
								open={location.pathname.includes(item.path)}
								handleClick={() => navigate(item.path)}
							/>
						);
					})}
				</List>
			</Stack>
			<Stack spacing={1.5} sx={styles.footer}>
				<Box sx={styles.farcentIconContainer}>
					<MenuIcon icon={FarcentIcon} sx={styles.farcentIcon} />
				</Box>
				<LogoutButton />
			</Stack>
		</Box>
	);
};

const useStyles = () => {
	const { bgcolor } = useMenuColor();

	return {
		container: {
			position: 'fixed',
			height: '100vh',
			width: '60px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			py: 2,
			boxShadow: `3px 0px 10px rgba(0, 0, 0, 0.1)`,
			bgcolor,
			zIndex: (theme: any) => theme.zIndex.appBar,
		},
		logoBox: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		listContainer: {
			overflow: 'auto',
		},
		list: {
			backgroundColor: 'transparent',
		},
		footer: {
			bottom: '24px !important',
			position: 'absolute',
		},
		farcentIconContainer: {
			textAlign: 'center',
			px: 0.5,
		},
		farcentIcon: {
			width: '32px',
			height: '32px',
		},
	};
};

export function isOpen({
	route,
	pathname,
}: {
	route: TMenuItem;
	pathname: string;
}) {
	if (Array.isArray(route.pathPatterns)) {
		return route.pathPatterns.some((pattern) => {
			const regexp = pathToRegexp(pattern);
			return regexp.test(pathname);
		});
	}
	return pathname.includes(route.path);
}

function getSideMenuItems(currRoutes: RouteObject[]) {
	let menuItems: TMenuItems[] = [];
	currRoutes.forEach((route) => {
		if (Array.isArray(route.children)) {
			let subRoutes = getSideMenuItems(route.children);
			if (subRoutes.length) {
				subRoutes = subRoutes.map((subRoute) => {
					let path = getAbsolutePath({ route, subRoute });
					return {
						...subRoute,
						path,
					};
				});
				if (route.breadcrumb && route.path) {
					menuItems.push({
						title: route.breadcrumb,
						path: route.path,
						children: subRoutes,
						icon: route.icon,
					});
				} else {
					menuItems = menuItems.concat(subRoutes);
				}
			}
		} else if (route.isSideMenuItem) {
			menuItems.push({
				title: route.breadcrumb ?? '',
				path: route.path ?? '',
				index: route.index ?? false,
				icon: route.icon,
			});
		}
	});
	return menuItems;
}

function getAbsolutePath({
	route,
	subRoute,
}: {
	route: RouteObject;
	subRoute: RouteObject;
}) {
	let path = subRoute.path || '';
	if (subRoute.index && !subRoute.path && route.path) {
		path = route.path;
	} else if (path.substring(0, 1) !== '/') {
		path = route.path + path;
	}
	return path;
}

export default SideMenu;
