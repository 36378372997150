import { alpha } from '@mui/material/styles';
import type { Components } from '@mui/material/styles/components';
import {
	BLACK,
	BLACK20,
	WHITE,
	ERROR,
	ERROR10,
	ALLY_BLUE,
} from '~/theme/colors';

const MuiButton: Components['MuiButton'] = {
	styleOverrides: {
		root: {
			borderRadius: '8px',
			cursor: 'pointer',
			'&.Mui-disabled': {
				boxShadow: 'none',
				pointerEvents: 'auto',
				cursor: 'not-allowed',
				background: 'inherit',
			},
			'&.MuiButton-sizeSmall': {
				height: '40px',
			},
			'&.MuiButton-sizeMedium': {
				height: '40px',
			},
			'&.MuiButton-sizeLarge': {
				height: '56px',
				fontSize: '16px',
			},
		},
	},
	variants: [
		{
			props: { variant: 'contained', color: 'primary' },
			style: {
				background: BLACK,
				'&:hover': {
					background: alpha(BLACK, 0.6),
				},
				'&:disabled': {
					color: WHITE,
					background: BLACK20,
				},
			},
		},
		{
			props: { variant: 'alert', color: 'primary' },
			style: {
				color: '#fff',
				background: ERROR,
				'&:hover': {
					background: alpha(ERROR, 0.6),
				},
				'&:disabled': {
					background: alpha(ERROR, 0.2),
				},
			},
		},
		{
			props: { variant: 'outlined', color: 'primary' },
			style: {
				color: BLACK,
				borderColor: BLACK,
				backgroundColor: 'white',
				'&:hover': {
					color: alpha(BLACK, 0.6),
					borderColor: alpha(BLACK, 0.6),
					background: 'inherit',
					backgroundColor: 'white',
				},
				'&:disabled': {
					color: BLACK20,
					borderColor: BLACK20,
					background: 'inherit',
				},
			},
		},
		{
			props: { variant: 'outlined', color: 'error' },
			style: {
				color: ERROR,
				borderColor: ERROR,
				backgroundColor: 'white',
				'&:hover': {
					color: alpha(ERROR, 0.6),
					borderColor: alpha(ERROR, 0.6),
					background: 'inherit',
					backgroundColor: 'white',
				},
				'&:disabled': {
					color: ERROR10,
					borderColor: ERROR10,
					background: 'inherit',
				},
			},
		},
		{
			props: { variant: 'ghost', color: 'primary' },
			style: {
				color: BLACK,
				fontWeight: 500,
				boxShadow: `0px 4px 8px 0px ${alpha(BLACK, 0.1)}`,
				'&:hover': {
					color: alpha(BLACK, 0.6),
					background: 'inherit',
				},
				'&:disabled': {
					color: BLACK20,
					background: 'inherit',
				},
			},
		},
		{
			props: { variant: 'text', color: 'primary' },
			style: {
				color: BLACK,
				'&:hover': {
					color: alpha(BLACK, 0.6),
					background: alpha(BLACK, 0.08),
				},
				'&:disabled': {
					color: BLACK20,
					background: 'inherit',
				},
			},
		},
		{
			props: { variant: 'text', color: 'info' },
			style: {
				color: ALLY_BLUE,
				'&:hover': {
					color: alpha(ALLY_BLUE, 0.6),
					background: alpha(ALLY_BLUE, 0.1),
				},
				'&:disabled': {
					color: alpha(ALLY_BLUE, 0.2),
					background: 'inherit',
				},
			},
		},
	],
};

export default MuiButton;
