import { lazy } from 'react';

const AdminPODListPage = lazy(
	() => import('./AdminPODListPage' /* webpackChunkName: 'AdminPODListPage' */)
);
const CarrierPODListPage = lazy(
	() =>
		import('./CarrierPODListPage' /* webpackChunkName: 'CarrierPODListPage' */)
);

const PODDetailPage = lazy(
	() => import('./PODDetailPage' /* webpackChunkName: 'PODDetailPage' */)
);

export { AdminPODListPage, PODDetailPage, CarrierPODListPage };
