import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import store from 'store2';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import history from '~/libraries/history';
import { SnackbarProvider } from 'notistack';
import SWRConfigProvider from './SWRConfigProvider';
import defaultTheme from './theme';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from '~/pages/ErrorBoundry';
import { liffId } from '~/utils/urls';
import './i18n.ts';
import './initSentry';

//[ATMS-816] https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
	const originalRemoveChild = Node.prototype.removeChild;
	(Node as any).prototype.removeChild = function (child: any) {
		if (child.parentNode !== this) {
			if (console) {
				console.error(
					'Cannot remove a child from a different parent',
					child,
					this
				);
			}
			return child;
		}
		return originalRemoveChild.apply(this, arguments as any);
	};

	const originalInsertBefore = (Node as any).prototype.insertBefore;
	(Node as any).prototype.insertBefore = function (
		newNode: any,
		referenceNode: any
	) {
		if (referenceNode && referenceNode.parentNode !== this) {
			if (console) {
				console.error(
					'Cannot insert before a reference node from a different parent',
					referenceNode,
					this
				);
			}
			return newNode;
		}
		return originalInsertBefore.apply(this, arguments);
	};
}

i18next.on('initialized', async () => {
	await window.liff?.init({
		liffId,
	});
	if (window.liff?.isInClient()) {
		store.remove('role');
	}
	const App = require('./App').default;
	ReactDOM.render(
		<React.StrictMode>
			<ThemeProvider theme={defaultTheme}>
				<ErrorBoundary>
					<SnackbarProvider
						maxSnack={5}
						autoHideDuration={3000}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						<SWRConfigProvider>
							<HistoryRouter history={history}>
								<App />
							</HistoryRouter>
						</SWRConfigProvider>
					</SnackbarProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</React.StrictMode>,
		document.getElementById('root')
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
