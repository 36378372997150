import { useRef, useEffect } from 'react';
import type { Location } from 'history';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Button } from '@ally-transport/mui';
import history from '~/libraries/history';
import logo from '~/static/blackLogo.svg';

const FallbackComponent = ({ resetError }: { resetError: Function }) => {
	const { t } = useTranslation();
	useEffect(() => {
		const unlisten = history.listen(({ location }) => {
			// browser navigate
			const { pathname, search, hash } = location;
			const path = `${pathname}${search}${hash}`;
			window.location.href = path;
		});
		return unlisten;
	}, [resetError]);
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			<Box>
				<img src={logo} alt='Ally Transport' />
			</Box>
			<Typography variant='h5' sx={{ mt: '80px' }}>
				{t('internalServerError')}
			</Typography>
			<Typography
				variant='body2'
				sx={{ mt: 2.5, whiteSpace: 'pre-line', textAlign: 'center' }}
			>
				{t('internalServerErrorDesc')}
			</Typography>
			<Button sx={{ mt: 5, minWidth: '320px' }} onClick={resetError}>
				{t('back')}
			</Button>
		</Box>
	);
};

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
	const locationsRef = useRef<Location[]>([history.location]);
	useEffect(() => {
		history.listen(({ location }) => {
			locationsRef.current.push(location);
			if (locationsRef.current.length > 10) {
				locationsRef.current = locationsRef.current.slice(
					locationsRef.current.length - 10,
					locationsRef.current.length
				);
			}
		});
	}, []);
	return (
		<Sentry.ErrorBoundary
			fallback={({ resetError }) => (
				<FallbackComponent resetError={resetError} />
			)}
			onReset={() => {
				// error boundry navigate
				let path = window.liff?.isInClient() ? '/driver' : '/';
				const prevLocation =
					locationsRef.current[locationsRef.current.length - 2];
				if (prevLocation) {
					const { pathname, search, hash } = prevLocation;
					path = `${pathname}${search}${hash}`;
				}
				window.location.href = path;
			}}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

export default ErrorBoundary;
