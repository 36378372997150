import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import useRouteData from '~/useRouteData';
import './App.css';
import '~/libraries/schema';

function App(): JSX.Element {
	const routes = useRouteData();
	const element = useRoutes(routes);
	return <Suspense fallback={null}>{element}</Suspense>;
}

export default App;
