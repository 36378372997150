import { useMemo } from 'react';
import store from 'store2';
import { SYSTEM_TYPE_ENUM } from '~/utils/urls';

const useRole = () => {
	const role = store.get('role');
	const [isAdmin, isShipper, isWarehouse, isCarrier] = useMemo(() => {
		return [
			role === SYSTEM_TYPE_ENUM.ADMIN,
			role === SYSTEM_TYPE_ENUM.SHIPPER,
			role === SYSTEM_TYPE_ENUM.WAREHOUSE,
			role === SYSTEM_TYPE_ENUM.CARRIER,
		];
	}, [role]);
	return { isAdmin, isShipper, isWarehouse, isCarrier, role };
};

export default useRole;
