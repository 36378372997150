import { lazy } from 'react';

const DispatchPage = lazy(
	() => import('./DispatchPage' /* webpackChunkName: 'DispatchPage' */)
);

const PendingPage = lazy(
	() => import('./PendingPage' /* webpackChunkName: 'PendingPage' */)
);

export { DispatchPage, PendingPage };
