import { lazy } from 'react';

const IssueDetailPage = lazy(
	() => import('./IssueDetailPage' /* webpackChunkName: 'IssueDetailPage' */)
);

const IssueListPage = lazy(
	() => import('./IssueListPage' /* webpackChunkName: 'IssueListPage' */)
);

export { IssueDetailPage, IssueListPage };
