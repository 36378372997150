const BLACK = '#231916';
const BLACK20 = '#BEBCBB';
const BLAKC60 = '#7B7573';
const ERROR = '#CD2A01';
const ERROR10 = '#FAEAE6';
const WARNING = '#CD7B01';
const WARNING10 = '#FAF2E6';
const INFO = '#2454FF';
const INFO10 = '#E9EEFF';
const BACKGROUND = '#F5F5F5';
const DIVIDER = '#DFDFDF';
const SUCCESS = '#42A68E';
const SUCCESS10 = '#ECF6F4';
const WHITE = '#FFFFFF';
const BLUE = '#356898';
const TAG_BLUE = '#C3D1DE';
const ALLY_BLUE = '#2454FF';
const ALLY_BLUE20 = '#D3DDFF';
const ALLY_BLUE60 = '#7C98FF';
const BACKGROUND_BLUE = '#EEF3F8';
const BACKGROUND_GRAY = '#F5F5F5';
const BACKGROUND_BLACK = '#4F4745';
const BACKGROUND_WHITE = '#F9F9F9';

export {
	BLACK,
	BLACK20,
	BLAKC60,
	ERROR,
	ERROR10,
	WARNING,
	WARNING10,
	SUCCESS,
	SUCCESS10,
	INFO,
	INFO10,
	BACKGROUND,
	DIVIDER,
	WHITE,
	BLUE,
	TAG_BLUE,
	ALLY_BLUE,
	ALLY_BLUE20,
	ALLY_BLUE60,
	BACKGROUND_BLUE,
	BACKGROUND_GRAY,
	BACKGROUND_BLACK,
	BACKGROUND_WHITE,
};
