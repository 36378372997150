import i18next from 'i18next';
import { useMemo } from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import type { DataGridProps as MuiDataGridProps } from '@mui/x-data-grid';
import { Typography, IconButton, Box, Stack } from '@mui/material';
import {
	gridPageCountSelector,
	useGridApiContext,
	useGridSelector,
	gridPaginationSelector,
	gridRowCountSelector,
	gridRowSelectionStateSelector,
	useGridApiEventHandler,
} from '@mui/x-data-grid';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface DataGridProps extends MuiDataGridProps {
	pageSize?: number;
	handleSelectedRowsChange?: Function;
	noRowsOverlayContent?: any;
	hideCustomFooter?: boolean;
	slots?: MuiDataGridProps['slots'];
}

const TablePaginationActions = () => {
	const apiRef = useGridApiContext();
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);
	const rowCount = useGridSelector(apiRef, gridRowCountSelector);
	const {
		paginationModel: { page, pageSize },
	} = useGridSelector(apiRef, gridPaginationSelector);
	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		apiRef.current.setPage(page - 1);
	};
	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		apiRef.current.setPage(page + 1);
	};
	const labelDisplayedRows = useMemo(() => {
		const from = pageSize * page + 1;
		const to = Math.min((page + 1) * pageSize, rowCount);
		if (rowCount <= 0) return i18next.t('noRowsLabel');
		return i18next.t('displayedRowsLabel', { from, to, rowCount });
	}, [page, pageSize, rowCount, pageCount]);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'
			>
				<KeyboardArrowLeft />
			</IconButton>
			<Typography sx={{ color: '#918C8A', fontSize: '12px' }}>
				{labelDisplayedRows}
			</Typography>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page === pageCount - 1}
				aria-label='next page'
			>
				<KeyboardArrowRight />
			</IconButton>
		</Box>
	);
};

const CustomNoRowsOverlay = ({ noRowsOverlayContent }: any) => {
	return (
		<Stack
			spacing={2.5}
			sx={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center',
				height: '100%',
			}}
		>
			{noRowsOverlayContent ?? ''}
		</Stack>
	);
};

const CustomFooter = ({
	hidden,
	checkboxSelection,
	handleSelectedRowsChange = () => {},
}: {
	hidden?: boolean;
	checkboxSelection: boolean;
	handleSelectedRowsChange?: Function;
}) => {
	const apiRef = useGridApiContext();
	const selectedRows = useGridSelector(apiRef, gridRowSelectionStateSelector);
	const selectedRowsLabel = useMemo(
		() => i18next.t('selectedRowsLabel', { count: selectedRows.length }),
		[selectedRows]
	);
	useGridApiEventHandler(
		apiRef,
		'rowSelectionChange',
		handleSelectedRowsChange as any
	);
	if (hidden) return <></>;
	return (
		<Box
			sx={{
				bgcolor: '#F5F5F5',
				height: '40px',
				display: 'flex',
				minHeight: '40px',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderRadius: '0px 0px 12px 12px',
			}}
		>
			<Box sx={{ ml: '16px' }}>
				{checkboxSelection && (
					<Typography sx={{ fontSize: 12, color: '#231916' }}>
						{selectedRowsLabel}
					</Typography>
				)}
			</Box>
			<TablePaginationActions />
		</Box>
	);
};

const DataGrid = ({
	pageSize = 100,
	rowHeight = 42,
	columnHeaderHeight = 42,
	checkboxSelection = true,
	disableColumnMenu = true,
	disableRowSelectionOnClick = true,
	hideFooterSelectedRowCount = true,
	hideCustomFooter = false,
	sx,
	slots,
	...props
}: DataGridProps) => {
	return (
		<MuiDataGrid
			sx={{
				width: '100%',
				height: '70vh',
				border: 0,
				'& .MuiDataGrid-row.Mui-selected': {
					bgcolor: '#E9EEFF',
				},
				'& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
					padding: '0px 10px',
					boxShadow: 'none',
					backgroundColor: 'transparent',
				},
				'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
					outline: 'none !important',
				},
				'& .MuiDataGrid-columnHeader': {
					outline: 'none !important',
				},
				'& .MuiDataGrid-columnHeaders': {
					bgcolor: 'color.background',
				},
				...sx,
			}}
			initialState={{
				pagination: {
					paginationModel: {
						pageSize,
					},
				},
			}}
			columnHeaderHeight={columnHeaderHeight}
			rowHeight={rowHeight}
			slots={{
				noRowsOverlay: () =>
					CustomNoRowsOverlay({
						noRowsOverlayContent: props.noRowsOverlayContent,
					}),
				footer: () =>
					CustomFooter({
						hidden: hideCustomFooter,
						checkboxSelection: checkboxSelection,
						handleSelectedRowsChange: props?.handleSelectedRowsChange,
					}),
				...slots,
			}}
			checkboxSelection={checkboxSelection}
			disableColumnMenu={disableColumnMenu}
			disableRowSelectionOnClick={disableRowSelectionOnClick}
			hideFooterSelectedRowCount={hideFooterSelectedRowCount}
			{...props}
		/>
	);
};

export default DataGrid;
