import { SvgIcon } from '@mui/material';
import useMenuColor from './useMenuColor';

const MenuIcon = ({ icon, sx }: any) => {
	const { svgIconColor } = useMenuColor();
	if (!icon) return <></>;
	return (
		<SvgIcon
			component={icon}
			inheritViewBox
			htmlColor={svgIconColor}
			sx={{ fillRule: 'evenodd', ...sx }}
		/>
	);
};

export default MenuIcon;
