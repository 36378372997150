import { lazy } from 'react';

const TallyingWarehousePage = lazy(
	() =>
		import(
			'./TallyingWarehousePage' /* webpackChunkName: 'TallyingWarehousePaths' */
		)
);

const TallyingWarehouseDetailPage = lazy(
	() =>
		import(
			'./TallyingWarehouseDetailPage' /* webpackChunkName: 'TallyingWarehousePaths' */
		)
);

export { TallyingWarehousePage, TallyingWarehouseDetailPage };
