import { lazy } from 'react';

const DriverCheckInPage = lazy(
	() =>
		import('./DriverCheckInPage' /* webpackChunkName: 'DriverCheckInPage' */)
);

const EnterDeliveryNoticePage = lazy(
	() =>
		import(
			'./EnterDeliveryNoticePage' /* webpackChunkName: 'EnterDeliveryNoticePage' */
		)
);

const DeliveryNoticePage = lazy(
	() =>
		import('./DeliveryNoticePage' /* webpackChunkName: 'DeliveryNoticePage' */)
);

const CreateActualTripPage = lazy(
	() =>
		import(
			'./CreateActualTripPage' /* webpackChunkName: 'CreateActualTripPage' */
		)
);

const HomePage = lazy(
	() => import('./HomePage' /* webpackChunkName: 'HomePage' */)
);

const AccidentReportPage = lazy(
	() =>
		import('./AccidentReportPage' /* webpackChunkName: 'AccidentReportPage' */)
);

const DeliveryReportPage = lazy(
	() =>
		import('./DeliveryReportPage' /* webpackChunkName: 'DeliveryReportPage' */)
);

const EnterDeliveryReportPage = lazy(
	() =>
		import(
			'./EnterDeliveryReportPage' /* webpackChunkName: 'EnterDeliveryReportPage' */
		)
);

const PODReturnPage = lazy(
	() => import('./PODReturnPage' /* webpackChunkName: 'PODReturnPage' */)
);

const NotFoundPage = lazy(
	() => import('./NotFoundPage' /* webpackChunkName: 'NotFoundPage' */)
);

export {
	CreateActualTripPage,
	DriverCheckInPage,
	EnterDeliveryNoticePage,
	DeliveryNoticePage,
	NotFoundPage,
	AccidentReportPage,
	HomePage,
	DeliveryReportPage,
	EnterDeliveryReportPage,
	PODReturnPage,
};
