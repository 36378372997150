import * as yup from 'yup';
import i18next from 'i18next';
import googleLibphonenumber from 'google-libphonenumber';

const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();
const regionCode = i18next.language.split('-')[1];

yup.setLocale({
	number: {
		max: ({ max }) => i18next.t('schema:number.max', { max }),
		moreThan: ({ more }) => `${more}`,
	},
	string: {
		max: ({ max }) => i18next.t('schema:string.max', { max }),
		email: i18next.t('schema:email'),
	},
	mixed: {
		required: i18next.t('schema:required'),
		notType: ({ path, type, value }) => {
			return i18next.t('schema:typeError');
		},
	},
});

// Yup plugins
yup.addMethod<yup.ObjectSchema<any>>(
	yup.object,
	'castOrDefault',
	function (value = {}): any {
		const option = {
			stripUnknown: true,
		};
		try {
			return this.cast(value, option);
		} catch (err) {
			return this.cast({}, option);
		}
	}
);

export const passwordSchema = yup
	.string()
	.required()
	.min(8, i18next.t('schema:password', { min: 8 }))
	.max(32, i18next.t('schema:string.max', { max: 32 }))
	.matches(
		/^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/,
		i18next.t('schema:password', { min: 8 })
	);

export const confirmPasswordSchema = (key: string) =>
	yup
		.string()
		.oneOf([yup.ref(key), null], i18next.t('schema:password.confirm'));

export const emailSchema = yup
	.string()
	.default('')
	.nullable()
	.email(i18next.t('schema:email'));

export const emailArraySchema = yup
	.array()
	.ensure()
	.default([])
	.test(function (emails: string[]) {
		const isValid = emails.every((email) =>
			yup.string().email().isValidSync(email)
		);
		if (!isValid) {
			return this.createError({
				path: this.path,
				message: i18next.t('schema:email'),
			});
		}
		return isValid;
	});

export const phoneNumberSchema = yup
	.string()
	.default('')
	.test((value, context) => {
		let isValid;
		try {
			isValid = phoneUtil.isValidNumberForRegion(
				phoneUtil.parse(value, regionCode),
				regionCode
			);
		} catch (e) {
			isValid = false;
		}
		if (value && !isValid) {
			return context.createError({
				message: i18next.t('schema:typeError'),
			});
		}
		return true;
	});

export const mobilePhoneSchema = phoneNumberSchema.matches(
	/^09[0-9]*$/,
	i18next.t('schema:typeError')
);

interface INnumberSchema {
	min?: number;
	minMessage?: string;
	max?: number;
	maxMessage?: string;
	isPositive?: boolean;
	positiveMessage?: string;
	isNegative?: boolean;
	negativeMessage?: string;
	isInteger?: boolean;
	integerMessage?: string;
	step?: number;
}

export const numberSchema = (props: INnumberSchema = {}) => {
	const {
		min,
		max,
		step,
		isPositive = false,
		isNegative = false,
		isInteger = false,
	} = props;
	let schema = yup
		.number()
		.transform((value, originalvalue) => {
			if (originalvalue === '' || originalvalue === undefined) return null;
			return value;
		})
		.nullable()
		.default(null)
		.test((value, context) => {
			if (value === null) return true;
			if (min !== undefined && value < min) {
				const minMessage =
					props.minMessage || i18next.t('schema:number.min', { min });
				return context.createError({
					message: minMessage,
				});
			} else if (max !== undefined && value > max) {
				const maxMessage =
					props.maxMessage || i18next.t('schema:number.max', { max });
				return context.createError({
					message: maxMessage,
				});
			}
			if (step && value % step !== 0) {
				return context.createError({
					message: i18next.t('schema:number.step', { step }),
				});
			}
			return true;
		})
		.typeError(i18next.t('schema:numberOnly'));
	if (isPositive) {
		const positiveMessage =
			props.positiveMessage || i18next.t('schema:number.positive');
		schema = schema.positive(positiveMessage);
	}
	if (isNegative && !isPositive) {
		const negativeMessage =
			props.negativeMessage || i18next.t('schema:number.negative');
		schema = schema.negative(negativeMessage);
	}
	if (isInteger) {
		const integerMessage =
			props.integerMessage || i18next.t('schema:numberOnly');
		schema = schema.integer(integerMessage);
	}
	return schema;
};

interface IAmountSchema {
	min: number;
	max: number;
}

export const amountSchema = (props: IAmountSchema) => {
	const { min, max } = props;
	const msg = i18next.t('schema:number.range', { min, max });
	return numberSchema({
		min,
		max,
		minMessage: msg,
		maxMessage: msg,
		isInteger: true,
		isPositive: true,
	});
};

export const moneySchema = (props?: IAmountSchema) => {
	const { min, max } = props ?? {};
	return numberSchema({
		min,
		max,
		isInteger: true,
		isPositive: true,
	});
};

export const timeSchema = () =>
	yup.string().test((value, context) => {
		let isValid;
		try {
			if (value) {
				isValid = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/.test(value);
			}
		} catch (e) {
			isValid = false;
		}
		if (value && !isValid) {
			return context.createError({
				message: i18next.t('schema:typeError'),
			});
		}
		return true;
	});

export const paginationSchema = (page = 1, limit = 100) =>
	yup.object({
		page: yup.number().default(page),
		limit: yup.number().default(limit),
	});
