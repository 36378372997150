import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import useSWRState from '~/hooks/useSWRState';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useOnLocationChange from '~/hooks/useOnLocationChange';
import { Container, Box, CssBaseline } from '@mui/material';
import EnvLabel from './EnvLabel';
import SideMenu from './SideMenu';
import watermark from '~/static/farcentWatermark.png';

const Layout = () => {
	const mainRef = useRef<HTMLHeadingElement>(null);
	const theme = useTheme();
	const styles = useStyles(theme);
	const { i18n } = useTranslation();
	const { data: showWatermark } = useSWRState('showWatermark');
	useOnLocationChange(() => {
		mainRef.current?.scrollTo(0, 0);
	});
	return (
		<Box sx={styles.layout}>
			<CssBaseline />
			<Box
				ref={mainRef}
				key={i18n.language}
				sx={styles.content}
				component='main'
			>
				<SideMenu />
				<Container maxWidth={false} sx={styles.container}>
					{['uat', 'staging'].includes(process.env.ENV_TYPE ?? '') && (
						<EnvLabel />
					)}
					{showWatermark && (
						<Box sx={styles.watermark}>
							<img src={watermark} alt='farcent_watermark' />
						</Box>
					)}
					<Outlet />
				</Container>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	layout: {
		display: 'flex',
	},
	container: {
		pt: 3,
		pb: 3,
		pl: '76px !important', // menu width 60 + 16
		pr: '16px !important',
		minHeight: '100%',
		minWidth: '1200px',
		width: `100vw !important`,
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		backgroundColor: 'background.paper',
	},
	watermark: {
		left: 'calc(50% - 200px)',
		top: 'calc(50% - 200px)',
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		pointerEvents: 'none',
		zIndex: 1000,
	},
});

export default Layout;
