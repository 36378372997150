import { lazy } from 'react';

const OSOPage = lazy(
	() => import('./OSOPage' /* webpackChunkName: 'OSOPage' */)
);
const ShipperOSOPage = lazy(
	() => import('./ShipperOSOPage' /* webpackChunkName: 'ShipperOSOPage' */)
);
const WarehouseOSOPage = lazy(
	() => import('./WarehouseOSOPage' /* webpackChunkName: 'WarehouseOSOPage' */)
);
const CarrierOSOPage = lazy(
	() => import('./CarrierOSOPage') /* webpackChunkName: 'CarrierOSOPage' */
);
const OSODetail = lazy(
	() => import('./OSODetail' /* webpackChunkName: 'OSODetail' */)
);
const EZCatUpload = lazy(
	() => import('./EZCatUpload' /* webpackChunkName: 'EZCatUpload' */)
);

export {
	OSOPage,
	ShipperOSOPage,
	WarehouseOSOPage,
	CarrierOSOPage,
	OSODetail,
	EZCatUpload,
};
