import { isValidElement } from 'react';
import { Box, Typography } from '@mui/material';

interface LabelTextProps {
	label: string;
	content: React.ReactNode | string;
}

const LabelText = ({ label, content }: LabelTextProps) => {
	return (
		<Box>
			<Typography variant='body2' color='color.black60' sx={{ mb: 0.5 }}>
				{label}
			</Typography>
			{isValidElement(content) ? (
				content
			) : (
				<Typography variant='body1'>{content}</Typography>
			)}
		</Box>
	);
};

export default LabelText;
