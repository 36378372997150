import axios, {
	AxiosRequestConfig,
	AxiosRequestHeaders,
	AxiosResponse,
	AxiosError,
} from 'axios';
import { apiURL, ssoURL } from '~/utils/urls';
import fetchToken from '~/utils/fetchToken';
import store from 'store2';

type PromiseQueue = {
	resolve: (value?: unknown) => void;
	reject: (reason?: unknown) => void;
}[];

let isRefreshing = false;

let queue: PromiseQueue = [];

const ajax = axios.create({
	baseURL: apiURL,
	headers: {
		'Content-Type': 'application/json',
	},
});
ajax.interceptors.request.use(interceptHeaders);
ajax.interceptors.response.use(successInterceptor, failureInterceptor);

function interceptHeaders(config: AxiosRequestConfig) {
	const headers: AxiosRequestHeaders = {
		'Content-Type': 'application/json',
		...config.headers,
	};

	// Authorization
	const authorization = store.get('token');
	if (authorization && config.baseURL?.includes(apiURL)) {
		headers.Authorization = `Bearer ${authorization}`;
	}

	return {
		...config,
		headers,
	};
}

function successInterceptor(response: AxiosResponse) {
	return response;
}

function failureInterceptor(error: AxiosError) {
	if (error.isAxiosError && error.response) {
		const { response } = error;
		switch (response.status) {
			case 401:
				return authTokenHandler(error);
			default:
				return Promise.reject(response);
		}
	}
	return Promise.reject(error);
}

async function authTokenHandler(error: AxiosError) {
	const originalRequest = error.config;
	if (isRefreshing) {
		return new Promise((resolve, reject) => {
			queue.push({ resolve, reject });
		})
			.then(() => {
				return ajax(originalRequest);
			})
			.catch(Promise.reject);
	} else {
		return await requestRefreshToken(originalRequest);
	}
}

async function requestRefreshToken(originalRequest: any): Promise<any> {
	try {
		isRefreshing = true;
		// 不使用ajax instance才不會進入 jwtHandler迴圈
		await fetchToken();
		const originalResponse = await ajax(originalRequest);
		resolveQueue();
		return originalResponse;
	} catch (error: any) {
		rejectQueue();
		store.remove('role');
		store.remove('token');
		store.remove('refreshToken');
		window.location.href = `${ssoURL}/home`;
	} finally {
		isRefreshing = false;
	}
}

function resolveQueue() {
	queue.forEach((p) => p.resolve());
	queue = [];
}

function rejectQueue() {
	queue.forEach((p) => p.reject());
	queue = [];
}

export default ajax;
