import { ListItemButton, Typography, Stack } from '@mui/material';
import useMenuColor from './useMenuColor';
import MenuIcon from './MenuIcon';

interface MenuTitleProps {
	title?: string;
	icon?: any;
	open: boolean;
	hasChildren?: boolean;
	handleClick?: () => void;
	listItemStyle?: object;
}

const MenuTitle = ({
	title,
	icon,
	open,
	hasChildren = false,
	handleClick,
	listItemStyle = {},
}: MenuTitleProps) => {
	const styles = useStyles(hasChildren, open);

	return (
		<ListItemButton
			onClick={handleClick}
			sx={{ ...styles.listItem, ...listItemStyle }}
			selected={open}
		>
			<Stack direction='column' justifyContent='center' alignItems='center'>
				<MenuIcon icon={icon} />
				<Typography variant='tooltip' sx={styles.listItemText}>
					{title}
				</Typography>
			</Stack>
		</ListItemButton>
	);
};

const useStyles = (hasChildren?: boolean, open?: boolean) => {
	const { bgcolor, menuTextColor, hoverColor } = useMenuColor();
	return {
		listItem: {
			mb: '12px',
			borderRadius: '6px',
			width: '50px',
			overflow: 'hidden',
			bgcolor: open ? hoverColor : bgcolor,
			'&.MuiListItemButton-root:hover': {
				backgroundColor: hoverColor,
			},
			'&.Mui-selected': {
				backgroundColor: hasChildren ? bgcolor : open ? hoverColor : bgcolor,
			},
			alignItem: 'center',
			justifyContent: 'center',
			py: 0.5,
		},
		listItemText: {
			color: menuTextColor,
			wordBreak: 'keep-all',
		},
	};
};

export default MenuTitle;
