import { IconButton as MuiIconButton } from '@mui/material';
import { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import useMenuColor from './useMenuColor';

interface IconButtonProps extends MuiIconButtonProps {
	clicked: boolean;
}

const IconButton = ({ sx, clicked, ...props }: IconButtonProps) => {
	const { hoverColor } = useMenuColor();
	return (
		<MuiIconButton
			sx={{
				width: '40px',
				height: '40px',
				borderRadius: '6px',
				textAlign: 'center',
				bgcolor: clicked ? hoverColor : 'transparent',
				'&:hover': {
					bgcolor: hoverColor,
					cursor: 'pointer',
				},
				...sx,
			}}
			{...props}
		/>
	);
};

export default IconButton;
