import { useEffect } from 'react';
import type { ReactElement } from 'react';
import useSWRState from '~/hooks/useSWRState';
import { ssoURL } from '~/utils/urls';

interface RequireAuthProps {
	children: ReactElement;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
	const { data: isLogin } = useSWRState('isLogin');

	useEffect(() => {
		if (!isLogin) {
			window.location.href = `${ssoURL}?service=${window.location.origin}/sso`;
		}
	}, [isLogin]);
	if (!isLogin) {
		return null;
	}
	return children;
};

export default RequireAuth;
