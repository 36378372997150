import { useMemo } from 'react';
import { lazy } from 'react';
import i18next from 'i18next';
import Layout from '~/components/Layout';
import LiffLayout from '~/pages/LiffPages/components/Layout';
import type { RouteObject } from 'react-router-dom';
import RequireAuth from '~/components/RequireAuth';
import * as ASNPages from '~/pages/ASNPages';
import * as OSOPages from '~/pages/OSOPages';
import * as LiffPages from '~/pages/LiffPages';
import * as NewDispatchPages from '~/pages/NewDispatchPages';
import * as IssuePages from '~/pages/IssuePages';
import * as PODPages from '~/pages/PODPages';
import * as ExternalLinksPages from '~/pages/ExternalLinksPages';
import { ReactComponent as ASNIcon } from '~/static/asn.svg';
import { ReactComponent as OSOIcon } from '~/static/oso.svg';
import { ReactComponent as ECIcon } from '~/static/ec.svg';
import { ReactComponent as CarrierIcon } from '~/static/carrier.svg';
import { ReactComponent as TallyingIcon } from '~/static/tallying.svg';
import { ReactComponent as DispatchIcon } from '~/static/calendar.svg';
import { ReactComponent as IssueIcon } from '~/static/issue.svg';
import { ReactComponent as PODsIcon } from '~/static/pods.svg';
import { ReactComponent as LinkIcon } from '~/static/link.svg';
import * as TallyingWarehousePaths from '~/pages/TallyingWarehouse';
import { SYSTEM_TYPE_ENUM } from '~/utils/urls';
import useRole from '~/hooks/useRole';

const NotFoundPage = lazy(() => import('~/pages/NotFoundPage'));
// const LoginPage = lazy(() => import('~/pages/LoginPage'));
const TallyPage = lazy(() => import('~/pages/TallyPage'));
const DispatchPage = lazy(() => import('~/pages/DispatchPage'));
const FinancePage = lazy(() => import('~/pages/FinancePage'));
const SsoPage = lazy(() => import('~/pages/SsoPage'));

const roleRoutes = (role: SYSTEM_TYPE_ENUM): RouteObject[] => [
	{
		path: '/',
		element: (
			<>
				<Layout />
			</>
		),
		children: [
			{
				path: '/',
				children: [
					{
						index: true,
						hidden: ![
							SYSTEM_TYPE_ENUM.ADMIN,
							SYSTEM_TYPE_ENUM.SHIPPER,
						].includes(role),
						breadcrumb: '',
						element: (
							<RequireAuth>
								<ASNPages.ASNsPage />
							</RequireAuth>
						),
					},
					{
						index: true,
						hidden: role !== SYSTEM_TYPE_ENUM.WAREHOUSE,
						breadcrumb: '',
						element: (
							<RequireAuth>
								<OSOPages.WarehouseOSOPage />
							</RequireAuth>
						),
					},
					{
						index: true,
						hidden: role !== SYSTEM_TYPE_ENUM.CARRIER,
						breadcrumb: i18next.t('oso'),
						element: (
							<RequireAuth>
								<OSOPages.CarrierOSOPage />
							</RequireAuth>
						),
					},
					{
						index: true,
						hidden: role !== null,
						element: (
							<RequireAuth>
								<></>
							</RequireAuth>
						),
					},
					{
						path: '/asn',
						icon: ASNIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						isSideMenuItem: true,
						breadcrumb: i18next.t('clientOrder'),
						element: (
							<RequireAuth>
								<ASNPages.ASNsPage />
							</RequireAuth>
						),
					},
					{
						path: '/asn',
						icon: ASNIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.SHIPPER,
						isSideMenuItem: true,
						breadcrumb: i18next.t('asn'),
						element: (
							<RequireAuth>
								<ASNPages.ASNsPage />
							</RequireAuth>
						),
					},
					{
						path: '/asn',
						icon: ASNIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.WAREHOUSE,
						isSideMenuItem: true,
						breadcrumb: i18next.t('asn'),
						element: (
							<RequireAuth>
								<ASNPages.WarehouseASNsPage />
							</RequireAuth>
						),
					},
					{
						path: '/oso',
						icon: OSOIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.CARRIER,
						isSideMenuItem: true,
						breadcrumb: i18next.t('oso'),
						element: (
							<RequireAuth>
								<OSOPages.CarrierOSOPage />
							</RequireAuth>
						),
					},
					{
						path: '/oso',
						icon: OSOIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						isSideMenuItem: true,
						breadcrumb: i18next.t('oso'),
						element: (
							<RequireAuth>
								<OSOPages.OSOPage />
							</RequireAuth>
						),
					},
					{
						path: '/oso',
						icon: OSOIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.SHIPPER,
						isSideMenuItem: true,
						breadcrumb: i18next.t('oso'),
						element: (
							<RequireAuth>
								<OSOPages.ShipperOSOPage />
							</RequireAuth>
						),
					},
					{
						path: '/oso',
						icon: OSOIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.WAREHOUSE,
						isSideMenuItem: true,
						breadcrumb: i18next.t('oso'),
						element: (
							<RequireAuth>
								<OSOPages.WarehouseOSOPage />
							</RequireAuth>
						),
					},
					{
						path: '/ec/ezcat-upload',
						hidden: role === SYSTEM_TYPE_ENUM.SHIPPER,
						breadcrumb: '',
						element: (
							<RequireAuth>
								<OSOPages.EZCatUpload />
							</RequireAuth>
						),
					},
					{
						path: '/asn/create',
						breadcrumb: '',
						element: (
							<RequireAuth>
								<ASNPages.CreateASN />
							</RequireAuth>
						),
					},
					{
						path: '/asn/batch/:filename/:id',
						hidden: role === SYSTEM_TYPE_ENUM.WAREHOUSE,
						breadcrumb: '',
						element: (
							<RequireAuth>
								<ASNPages.BatchASNPage />
							</RequireAuth>
						),
					},
					{
						path: '/asn/:orderId/:id',
						breadcrumb: '',
						// hidden: role === SYSTEM_TYPE_ENUM.WAREHOUSE,
						element: (
							<RequireAuth>
								<ASNPages.ASNDetail />
							</RequireAuth>
						),
					},
					{
						path: '/oso/:orderId/:id',
						breadcrumb: '',
						element: (
							<RequireAuth>
								<OSOPages.OSODetail />
							</RequireAuth>
						),
					},
					{
						path: '/ec/:orderId/:id',
						breadcrumb: '',
						element: (
							<RequireAuth>
								<OSOPages.OSODetail />
							</RequireAuth>
						),
					},
					{
						path: '/dispatch',
						breadcrumb: i18next.t('dispatchCalendar'),
						icon: DispatchIcon,
						isSideMenuItem: true,
						hidden: true, //role !== SYSTEM_TYPE_ENUM.ADMIN,
						element: (
							<RequireAuth>
								<DispatchPage />
							</RequireAuth>
						),
					},
					{
						path: '/new_dispatch',
						breadcrumb: i18next.t('dispatch:trips'),
						icon: CarrierIcon,
						isSideMenuItem: true,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						element: (
							<RequireAuth>
								<NewDispatchPages.DispatchPage />
							</RequireAuth>
						),
					},
					{
						path: '/pending',
						breadcrumb: i18next.t('dispatch:pendingOSOs'),
						icon: DispatchIcon,
						isSideMenuItem: true,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						element: (
							<RequireAuth>
								<NewDispatchPages.PendingPage />
							</RequireAuth>
						),
					},
					{
						path: '/tallying_warehouse',
						breadcrumb: i18next.t('tally:tallyTitle'),
						// isSideMenuItem: true,
						icon: TallyingIcon,
						element: <TallyingWarehousePaths.TallyingWarehousePage />,
					},
					{
						path: '/ec',
						icon: ECIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						isSideMenuItem: true,
						breadcrumb: i18next.t('ec'),
						element: (
							<RequireAuth>
								<OSOPages.OSOPage key={'ec'} />
							</RequireAuth>
						),
					},
					{
						path: '/ec',
						icon: ECIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.SHIPPER,
						isSideMenuItem: true,
						breadcrumb: i18next.t('ec'),
						element: (
							<RequireAuth>
								<OSOPages.ShipperOSOPage key={'ec'} />
							</RequireAuth>
						),
					},
					{
						path: '/ec',
						icon: ECIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.WAREHOUSE,
						isSideMenuItem: true,
						breadcrumb: i18next.t('ec'),
						element: (
							<RequireAuth>
								<OSOPages.WarehouseOSOPage key={'ec'} />
							</RequireAuth>
						),
					},
					{
						path: '/pods',
						breadcrumb: i18next.t('pods'),
						icon: PODsIcon,
						isSideMenuItem: true,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						element: (
							<RequireAuth>
								<PODPages.AdminPODListPage />
							</RequireAuth>
						),
					},
					{
						path: '/pods/:code',
						breadcrumb: i18next.t('pods'),
						icon: PODsIcon,
						isSideMenuItem: false,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						element: (
							<RequireAuth>
								<PODPages.PODDetailPage />
							</RequireAuth>
						),
					},
					{
						path: '/pods',
						breadcrumb: i18next.t('pods'),
						icon: PODsIcon,
						isSideMenuItem: true,
						hidden: role !== SYSTEM_TYPE_ENUM.CARRIER,
						element: (
							<RequireAuth>
								<PODPages.CarrierPODListPage />
							</RequireAuth>
						),
					},
					{
						path: '/pods/:code',
						breadcrumb: i18next.t('pods'),
						icon: PODsIcon,
						isSideMenuItem: false,
						hidden: role !== SYSTEM_TYPE_ENUM.CARRIER,
						element: (
							<RequireAuth>
								<PODPages.PODDetailPage />
							</RequireAuth>
						),
					},
					{
						path: '/issue',
						icon: IssueIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						isSideMenuItem: true,
						breadcrumb: i18next.t('issue'),
						element: (
							<RequireAuth>
								<IssuePages.IssueListPage />
							</RequireAuth>
						),
					},
					{
						path: '/issue/:code',
						icon: IssueIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						isSideMenuItem: false,
						breadcrumb: i18next.t('issue'),
						element: (
							<RequireAuth>
								<IssuePages.IssueDetailPage />
							</RequireAuth>
						),
					},
					{
						path: 'finance',
						isSideMenuItem: false,
						breadcrumb: '',
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						element: (
							<RequireAuth>
								<FinancePage />
							</RequireAuth>
						),
					},
					{
						path: '/external_links',
						icon: LinkIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.ADMIN,
						isSideMenuItem: true,
						breadcrumb: i18next.t('file:externalLink'),
						element: (
							<RequireAuth>
								<ExternalLinksPages.ExternalLinksPage />
							</RequireAuth>
						),
					},
					{
						path: '/external_links',
						icon: LinkIcon,
						hidden: role !== SYSTEM_TYPE_ENUM.CARRIER,
						isSideMenuItem: true,
						breadcrumb: i18next.t('file:externalLink'),
						element: (
							<RequireAuth>
								<ExternalLinksPages.ExternalLinksPage />
							</RequireAuth>
						),
					},
				],
			},
		],
	},
	{
		path: `/sso`,
		element: <SsoPage />,
	},
	// {
	// 	path: '/login',
	// 	element: <LoginPage />,
	// },
	{
		path: '/driver',
		element: <LiffLayout />,
		children: [
			{
				path: '',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.HomePage />,
			},
			{
				path: 'check-in',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.DriverCheckInPage />,
			},
			{
				path: 'trip-confirm/:expectedTripId',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.CreateActualTripPage />,
			},
			{
				path: 'enter-delivery-notice',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.EnterDeliveryNoticePage />,
			},
			{
				path: 'delivery-notice/:id',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.DeliveryNoticePage />,
			},
			{
				path: 'accident-report/:tripName',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.AccidentReportPage />,
			},
			{
				path: 'enter-delivery-report',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.EnterDeliveryReportPage />,
			},
			{
				path: 'delivery-report/:shipperWarehouseShippingOrder',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.DeliveryReportPage />,
			},
			{
				path: 'pod-return',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.PODReturnPage />,
			},
			{
				path: '*',
				isSideMenuItem: false,
				hidden: false,
				element: <LiffPages.NotFoundPage />,
			},
		],
	},
	{
		path: '/tallying',
		element: <TallyPage />,
	},
	{
		path: '/tallying_warehouse/:date/:tripName',
		element: <TallyingWarehousePaths.TallyingWarehouseDetailPage />,
	},
	{
		path: '*',
		breadcrumb: 'Not Found',
		element: (
			<RequireAuth>
				<NotFoundPage />
			</RequireAuth>
		),
	},
];

function removeHiddenRoutes(currRoutes: RouteObject[]) {
	const newRoutes: RouteObject[] = [];
	currRoutes.forEach((routeObject) => {
		if (!routeObject.hidden) {
			if (Array.isArray(routeObject.children)) {
				routeObject.children = removeHiddenRoutes(routeObject.children);
			}
			newRoutes.push(routeObject);
		}
	});
	return newRoutes;
}

const useRouteData = () => {
	const { role } = useRole();
	const routes = useMemo(() => {
		return removeHiddenRoutes(roleRoutes(role));
	}, [role]);
	return routes;
};

export default useRouteData;
