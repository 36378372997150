import { Box, Typography } from '@mui/material';

const EnvLabel = () => {
	const styles = useStyles();
	return (
		<Box sx={styles.container}>
			<Typography variant='body2' sx={styles.text}>
				{`${process.env.ENV_TYPE}Mode`}
			</Typography>
		</Box>
	);
};

const useStyles = () => ({
	container: {
		position: 'fixed',
		top: 0,
		right: 'calc(50% - 130px)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '260px',
		height: '40px',
		backgroundColor: 'color.black',
		zIndex: 1024,
		borderRadius: '0px 0px 16px 16px',
	},
	text: {
		color: '#fff',
	},
});

export default EnvLabel;
