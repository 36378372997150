import { useMemo } from 'react';
// import { systemType, SYSTEM_TYPE_ENUM } from '~/utils/urls';

export enum SYSTEM_TYPE_ENUM {
	ADMIN = 'ADMIN',
	SHIPPER = 'SHIPPER',
	WAREHOUSE = 'WAREHOUSE',
}

const systemType: any = SYSTEM_TYPE_ENUM.ADMIN;

export const useMenuColor = () => {
	return useMemo(() => {
		let [
			svgIconColor,
			bgcolor,
			menuTextColor,
			hoverColor,
			statusBgcolor,
			selectedStatusBgcolor,
		] = Array(6).fill('white');

		switch (systemType) {
			case SYSTEM_TYPE_ENUM.ADMIN:
				svgIconColor = '#F5F5F5';
				menuTextColor = 'color.bgGray';
				bgcolor = 'color.black';
				hoverColor = 'color.bgBlack';
				statusBgcolor = 'color.divider';
				selectedStatusBgcolor = 'color.divider';
				break;
			case SYSTEM_TYPE_ENUM.SHIPPER:
				svgIconColor = '#7B7573';
				menuTextColor = 'color.black60';
				bgcolor = 'white';
				hoverColor = 'color.divider';
				statusBgcolor = 'color.black8';
				selectedStatusBgcolor = 'white';
				break;
			case SYSTEM_TYPE_ENUM.WAREHOUSE:
				svgIconColor = '#7B7573';
				menuTextColor = 'color.black60';
				bgcolor = 'color.bgGray';
				hoverColor = 'color.divider';
				statusBgcolor = 'color.black8';
				selectedStatusBgcolor = 'white';
				break;
			default:
				break;
		}
		return {
			svgIconColor,
			bgcolor,
			menuTextColor,
			hoverColor,
			statusBgcolor,
			selectedStatusBgcolor,
		};
	}, []);
};

export default useMenuColor;
