import React, { useMemo } from 'react';
import store from 'store2';
// import { useTranslation } from 'react-i18next';
import { SWRConfiguration, SWRConfig } from 'swr';
// import useEnqueueSnackbar from '~/hooks/useEnqueueSnackbar';
import ajax from '~/libraries/ajax';
interface Configuration extends Omit<SWRConfiguration, 'provider'> {
	provider?: () => any;
}

const initialState = new Map([
	// ['isLogin', store.has('role')],
	['isLogin', store.has('token')],
	['showWatermark', !!store.get('showWatermark')],
]);
// const initialState = new Map([['isLogin', store.has('token')]]);

const SWRConfigProvider = ({ children }: { children: React.ReactNode }) => {
	// const { t } = useTranslation();
	// const pushSnackbar = useEnqueueSnackbar();

	const config: Configuration = useMemo(() => {
		return {
			errorRetryCount: 0,
			revalidateOnFocus: false,
			provider: () => initialState,
			fetcher: (resource) =>
				ajax(resource).then(({ data }: any) => {
					const { result, message, status, ...rest } = data ?? {};
					return {
						...rest,
						data: result,
						message: message,
						responseStatus: status,
					};
				}),
			onError: (err) => {
				return err;
			},
		};
	}, []);
	return <SWRConfig value={config}>{children}</SWRConfig>;
};

export default SWRConfigProvider;
