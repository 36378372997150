export enum SYSTEM_TYPE_ENUM {
	ADMIN = 0,
	SHIPPER = 1,
	WAREHOUSE = 2,
	CARRIER = 3,
}

const apiURLs: any = {
	production: 'https://orbit-api.allytransport.com.tw',
	uat: 'https://orbit-api.uat.allytransport.com.tw',
	staging: 'https://orbit-api.dev.allytransport.com.tw',
};

const liffIds: any = {
	production: '2000232993-xlqe9npw',
	uat: '2000361270-PLOo5kvW',
	staging: '2000118039-vxNwqZRy',
};

export const ssoURL =
	process.env.ENV_TYPE === 'production'
		? 'https://sso.auth.allytransport.com.tw'
		: 'https://sso.auth-dev.allytransport.com.tw';

export const ssoApiURL =
	process.env.ENV_TYPE === 'production'
		? 'https://api.auth.allytransport.com.tw/api'
		: 'https://api.auth-dev.allytransport.com.tw/api';

export const apiURL = apiURLs[process.env.ENV_TYPE as any] ?? apiURLs.staging;
export const liffId = liffIds[process.env.ENV_TYPE as any] ?? liffIds.staging;
