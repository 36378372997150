import { alpha } from '@mui/material/styles';
import { common } from '@mui/material/colors';
import type { Components } from '@mui/material/styles/components';
import { BLACK, ERROR, TAG_BLUE, ALLY_BLUE } from '~/theme/colors';

const MuiTextField: Components['MuiTextField'] = {
	variants: [
		{
			props: { variant: 'standard' },
			style: {
				zIndex: 0,
				minHeight: '56px',
				'&.Customized-small': {
					minHeight: '40px',
				},
				'&.Customized-noLabel.Customized-small': {
					'& label': {
						display: 'none',
					},
					'& input': {
						marginTop: '2px !important',
					},
				},
				'& .Mui-disabled': {
					backgroundColor: '#EDEDEC !important',
					borderColor: 'transparent !important',
					WebkitTextFillColor: 'inherit !important',
					'& input': {
						color: '#A7A3A2 !important',
					},
					'& label': {
						color: '#918C8A !important',
						backgroundColor: 'transparent !important',
					},
				},
				'& > label': {
					zIndex: 1,
					color: alpha(BLACK, 0.6),
					marginLeft: '12px',
					pointerEvents: 'none',
				},
				'&.Customized-small > label': {
					transform: 'translate(0, 8px) scale(1)',
					backgroundColor: 'transparent !important',
				},
				'&.Customized-small > label.MuiInputLabel-shrink': {
					transform: 'translate(0, -7px) scale(0.75)',
				},
				'& > label .MuiSvgIcon-root': {
					pointerEvents: 'auto',
				},
				'& > label.MuiInputLabel-shrink': {
					marginTop: '8px',
				},
				'& > label.Mui-focused label': {
					color: alpha(BLACK, 0.6),
				},
				'& label.Mui-error': {
					color: 'inherit',
				},
				'& .MuiInputBase-root': {
					minHeight: '56px',
					margin: 0,
					color: BLACK,
					border: `1px solid ${alpha(BLACK, 0.08)}`,
					borderRadius: 8,
					overflow: 'hidden',
				},
				'&.Customized-small .MuiInputBase-root': {
					minHeight: '40px',
				},
				'&.Customized-small fieldset': {
					minHeight: '40px',
					borderRadius: '0px important!',
				},
				'& .MuiInputBase-root.Mui-focused': {
					borderColor: ALLY_BLUE,
				},
				'& .MuiInputBase-root.Mui-error': {
					borderColor: ERROR,
				},
				'& input': {
					padding: '0 12px 0px 12px !important',
					marginTop: '18px',
					height: 'auto',
				},
				'&.Customized-small input': {
					marginTop: '14px',
					padding: '0 12px 0 12px !important',
				},
				'& textarea': {
					padding: '0 12px 8px 12px !important',
					marginTop: '22px',
				},

				'& input:-internal-autofill-selected': {
					WebkitBoxShadow: `0 0 0 1000px ${common.white} inset`,
					WebkitTextFillColor: 'inherit !important',
				},
				'& .MuiInputAdornment-positionStart': {
					marginTop: '12px',
					marginLeft: '12px',
					marginRight: '-4px',
				},
				'& .MuiInputAdornment-positionEnd': {
					marginLeft: '-4px',
					marginRight: '12px',
				},
				'& :before': {
					display: 'none',
				},
				'& :after': {
					display: 'none',
				},
				// Autocomplete
				'& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
					paddingBottom: 0,
				},
				// TagInputField
				'& .TagInput-Box': {
					marginLeft: '6px',
					marginTop: '15px',
				},
				'& .TagInput-Box:empty': {
					marginTop: 0,
				},
				'& .TagInput-Box:not(:empty) + input': {
					padding: '0 0 0 12px',
				},
				'& .TagInput-MuiChip': {
					margin: '5px 0 0 6px',
					color: BLACK,
					fontSize: '12px',
					background: TAG_BLUE,
				},
				'& .TagInput-MuiChip .MuiChip-deleteIcon': {
					fontSize: '13px',
					borderRadius: '8px',
					overflow: 'hidden',
					background: TAG_BLUE,
					'& path': {
						fill: BLACK,
					},
				},
				// SearchTextField
				'&.Customized-search .MuiInputBase-root': {
					borderRadius: '8px 0 0 8px',
				},
			},
		},
		{
			props: { variant: 'outlined' },
			style: {
				'& .MuiInputBase-root': {
					color: BLACK,
					border: `1px solid ${alpha(BLACK, 0.08)}`,
					boxSizing: 'border-box',
					height: '40px',
					borderRadius: 8,
					overflow: 'hidden',
					bgcolor: 'white',
				},
				'& .MuiOutlinedInput-notchedOutline': {
					border: 0,
				},
				'& legend': { display: 'none' },
				'& fieldset': { top: 0 },
				'& .Mui-disabled': {
					backgroundColor: '#EDEDEC !important',
					borderColor: 'transparent !important',
					WebkitTextFillColor: 'inherit !important',
					'& input': {
						color: '#A7A3A2 !important',
					},
					'& label': {
						color: '#918C8A !important',
					},
				},
				'& .MuiOutlinedInput-root.Mui-focused': {
					border: '1px solid',
					borderColor: ALLY_BLUE,
					borderRadius: 8,
				},
				'& .MuiOutlinedInput-root.Mui-error': {
					borderColor: ERROR,
				},
			},
		},
	],
};

export default MuiTextField;
