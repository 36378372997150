import VConsole from 'vconsole';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Layout = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	useEffectOnce(() => {
		if (process.env.ENV_TYPE !== 'production') {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const vConsole = new VConsole();
		}
	});
	useEffectOnce(() => {
		if (process.env.NODE_ENV !== 'development') {
			if (!window.liff.isInClient() || !window.liff.isLoggedIn()) {
				alert(t('liff:pleaseOpenViaLine'));
				navigate('/driver');
			}
		} else {
			if (!window.liff.isLoggedIn()) {
				window.liff.login();
			}
		}
	});
	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				bgcolor: 'color.background',
				minHeight: window.innerHeight,
				boxSizing: 'border-box',
			}}
		>
			<Outlet />
		</Box>
	);
};

export default Layout;
