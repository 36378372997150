import store from 'store2';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoutIcon } from '~/static/logout.svg';
import { ssoURL } from '~/utils/urls';
import MenuTitle from './MenuTitle';

const LogoutButton = () => {
	const { t } = useTranslation();
	const { cache } = useSWRConfig();

	const handleLogout = () => {
		cache.clear();
		store.remove('role');
		store.remove('token');
		store.remove('refreshToken');
		window.location.href = `${ssoURL}/logout`;
	};

	return (
		<MenuTitle
			title={t('logout')}
			icon={LogoutIcon}
			hasChildren={false}
			open={false}
			handleClick={handleLogout}
		/>
	);
};

export default LogoutButton;
